import _ from "lodash";

import { logError } from "./log";
import { isDebug } from "config";
import { useState } from "react";

const prefixCacheKeyIfNeeded = (key) => {
  if (!key.startsWith("gro-upsell::")) {
    key = `gro-funnels::${key}`;
  }
  return key;
};

const LocalStorageCache = (() => {
  const store = (key, value) => {
    if (!value) {
      return;
    }
    try {
      window.localStorage.setItem(
        prefixCacheKeyIfNeeded(key),
        JSON.stringify(value)
      );
    } catch (e) {
      logError(`Could not access session storage: ${e}`);
    }
  };

  const load = (key) => {
    try {
      const stored = window.localStorage.getItem(prefixCacheKeyIfNeeded(key));
      return JSON.parse(stored);
    } catch (e) {
      logError(`Could not access session storage: ${e}`);
      return null;
    }
  };

  const clear = (key) => {
    window.localStorage.clear(prefixCacheKeyIfNeeded(key));
  };

  return { load, store, clear };
})();

const SessionCache = (() => {
  const store = (key, value) => {
    if (!value) {
      return;
    }
    try {
      window.sessionStorage.setItem(
        prefixCacheKeyIfNeeded(key),
        JSON.stringify(value)
      );
    } catch (e) {
      logError(`Could not access session storage: ${e}`);
    }
  };

  const load = (key) => {
    try {
      const stored = window.sessionStorage.getItem(prefixCacheKeyIfNeeded(key));
      return JSON.parse(stored);
    } catch (e) {
      logError(`Could not access session storage: ${e}`);
      return null;
    }
  };

  const clear = (key) => {
    window.sessionStorage.clear(prefixCacheKeyIfNeeded(key));
  };

  return { load, store, clear };
})();

const InMemoryCache = (() => {
  const cache = {};

  if (isDebug) {
    window.Gro = window.Gro || {};
    window.Gro.InMemoryCache = cache;
  }

  return {
    load: (key) => cache[key] || null,
    store: (key, val) => (cache[key] = val),
    clear: (key) => delete cache[key],
  };
})();

const getCache = (options) => {
  try {
    if (options?.useLocalStorage && Boolean(window?.localStorage?.getItem)) {
      return LocalStorageCache;
    } else if (Boolean(window?.sessionStorage?.getItem)) {
      return SessionCache;
    }
  } catch (e) {}
  return InMemoryCache;
};

export const setCached = (key, value, options) => {
  const cache = getCache(options);
  cache.store(key, value);
};

export const getCached = (key, options) => {
  const cache = getCache(options);
  return cache.load(key);
};

export const clearCached = (key) => {
  const cache = getCache();
  return cache.clear(key);
};

export const setCachedShop = (shop) => {
  setCached("shop", shop);
};

export const getCachedShopProp = (propPath) => {
  const shop = getCached("shop");
  return _.get(shop, propPath);
};

export const upsertCachedEntity = (type, id, data) => {
  setCached(`cached.${type}.${id}`, data);
};

export const getCachedEntity = (type, id) => {
  return _.cloneDeep(getCached(`cached.${type}.${id}`));
};

export const updateProductCache = (id, data) =>
  upsertCachedEntity("products", id, data);

export const getCachedProduct = (id) => getCachedEntity("products", id);

export const updateCollectionsCache = (id, data) =>
  upsertCachedEntity("collections", id, data);

export const getCachedCollection = (id) => getCachedEntity("collections", id);

export const useCachedState = ({ defaultValue, cacheKey }) => {
  const initValue = getCached(cacheKey) || defaultValue;

  const [memValue, setMemValue] = useState(initValue);

  const setValue = (newValue) => {
    setCached(cacheKey, newValue);
    setMemValue(newValue);
  };

  return [memValue, setValue];
};
